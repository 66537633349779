<template>
  <el-card>
    <div slot="header">
      <h2>{{$t('page.console.menu.list')}}</h2>
    </div>

    <el-button type="primary" size="mini" @click="goTo">{{$t('button.add')}}</el-button>
    <el-button type="danger" :disabled="disabled" size="mini" @click="removeProduct(deleteCategory)">{{$t('button.del')}}</el-button>

    <el-form :inline="true" :model="search" class="mt20">
      <el-form-item :label="$t('page.product.name')">
        <el-input v-model="search.query" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item :label="$t('page.console.menu.category')">
        <el-cascader
            ref="category"
            v-model="search.category"
            :options="categoryList"
            :props="{
              // multiple: true,
              label: 'name',
              value: 'id',
              checkStrictly: true
            }"
            size="mini"
            clearable></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" size="mini">{{$t('search')}}</el-button>
      </el-form-item>
    </el-form>

    <el-table
        class="mt20"
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        size="small"
        @selection-change="productChange"
        tooltip-effect="dark">
      <el-table-column
          type="selection">
      </el-table-column>
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          :prop="isEn+`title`"
          :label="$t('page.product.name')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          :prop="isEn+`desc`"
          :label="$t('page.product.desc')"
          show-overflow-tooltip>
      </el-table-column>
      <!--<el-table-column
          :label="$t('form.status')">
        <template v-slot="scope">
          <el-link type="info" :underline="false">{{status[scope.row.status]}}</el-link>
        </template>
      </el-table-column>-->
      <!--<el-table-column
          prop="username"
          label="创建用户">
      </el-table-column>-->
      <el-table-column
          prop="create_time"
          :label="$t('create_time')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="update_time"
          :label="$t('update_time')"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          width="200"
          :label="$t('action')">
        <template v-slot="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit(scope.row.id)">{{$t('button.edit')}}</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeProduct(scope.row.id)">{{$t('button.del')}}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        class="mt20"
        :hide-on-single-page="onePage"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @size-change="changeSize"
        @current-change="changeSize"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

  </el-card>
</template>

<script>
import {getTaxonomyAll, getProduct, removeProduct} from "@/api/os/admin";
import config from '@/config';

export default {
  data() {
    return {
      list: [],
      multiple: false,
      currentPage: 0,
      pageSize: config.default.pageLimit,
      pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,
      status: config.default.productStatus,
      // 删除数据
      deleteCategory: '',
      disabled: true,
      selected: 0,

      categoryList: [],
      search: {
        query: '',
        type: ''
      },
      type: ''
    }
  },
  created() {
    this.get()

    this.getCategory()
  },
  computed: {
    // add
    isEn() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }

      return 'en_';
    }
  },
  methods: {
    getCategory() {
      getTaxonomyAll({}).then(res => {
        this.categoryList = res.data.data
      })
    },
    get() {
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        query: this.search.query,
        type: this.type,
        order: 'desc'
      }

      this.loading = true
      getProduct(params).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    changeSize() {
      this.get()
    },
    goTo() {
      this.$router.push('/console/product')
    },
    edit(id) {
      this.$router.push({
        path: 'product',
        query: {
          id: id
        }
      })
    },
    productChange(selection) {
      let data = []
      selection.forEach((v, i) => {
        data.push(v.id)
      })
      this.selected = data.length
      this.deleteCategory = data.join(',')

      if(this.deleteCategory) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    // 删除选中的id
    removeProduct(id) {
      if(!id) {
        this.$message.error(this.$t('tips.not_selected'))
        return
      }
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeProduct(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.get()
          }
        })
      })
    },
    onSearch() {
      let taxonomy = [];
      let productType = this.$refs['category'].getCheckedNodes()
      productType.forEach((e, i) => {
        taxonomy.push(e.value)
      })
      this.currentPage = 0;
      this.type = taxonomy.join(',');

      this.get()
    }
  }
}
</script>

<style lang="less" scoped>
.images-upload{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 128px;
  cursor: pointer;
  line-height: 126px;
  vertical-align: top;
  text-align: center;
  i{
    font-size: 28px;
    color: #8c939d;
  }
}
.images-preview{
  flex-wrap: wrap;
  flex-direction: row;
  .el-col{
    margin-bottom: 20px;
  }
  .images-preview-center{
    position: relative;
    height: 100%;
    &:hover{
      .images-preview-mask {
        display: block;
      }
    }
    .el-image{
      width: 100%;
      //height: 100px;
      display: block;
      cursor: pointer;
    }
    .images-preview-mask{
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,.6);
      display: none;
      text-align: center;
      .el-button{
      }
    }
  }
}
</style>